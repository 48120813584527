import React from 'react'
import { Navbar } from 'react-bootstrap'
type Props = {
  children: React.ReactNode
  title: string
}

const Layout = ({ title, children }: Props) => {
  return (
    <>
      <title>{title} | EHACare Appointment</title>
      <meta charSet='utf-8' />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <link rel='shortcut icon' href='/img/favicon-32x32.png' />

      <Navbar bg='dark' variant='dark' expand='md' fixed='top' className='eha-topnav'>
        <Navbar.Brand href='/' className='mx-3'>
          <img src='/img/EHABooking-logo.svg' height='40px' className='pt-2 ps-2' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' className='mx-3' />
      </Navbar>
      <main className='main'>{children}</main>

      <footer className='App-footer'>copyright 2025 EHA Clinics Ltd</footer>
    </>
  )
}
export default Layout
