import React from 'react';


interface inputProps {
  type:string,
  name:string,
  value?:string,
  placeholder?:string,
  ref?:any,
  autoComplete?:string,
  required?:boolean,
  accept?:string,
  register?:any,
  onChange?:(ev:any) => void
}

const Input = (props:inputProps) => {
  const {register, ...otherProps} = props
  return (
    <div className={`input-wrapper ${props.value ? 'filled' : 'empty'}`} >
      {register? <input {...register(props.name)} autoComplete='off' {...otherProps} /> : <input autoComplete='off' {...otherProps} />}
    </div>
  );
}

Input.displayName = 'Input';

export default Input;
