import React, { useState } from 'react';
interface FilterBarProps {
  search?: React.ReactNode;
  appliedFilters?: React.ReactNode;
  children: React.ReactNode;
}

const FilterBar = ({ search, appliedFilters, children }: FilterBarProps) => {
  const [collapsed, setActive] = useState(true);

  const handleFilterBarToggle = () => {
    setActive(!collapsed);
  };

  return (
    <div className={`collapsible-filterbar  ${collapsed ? 'collapsed' : ''}`}>
      <div className="filters-top d-flex flex-wrap flex-sm-nowrap justify-content-end">
        <div className="filterbar-search d-flex align-items-center px-3 py-2 p-md-3">
          {search}
        </div>
        <div
          onClick={handleFilterBarToggle}
          className="applied-filters d-flex flex-wrap align-items-center justify-content-end px-2 px-md-3 py-1 border-sm-left border-info flex-grow-1">
          <span className="label">Filtered by</span>
          {appliedFilters}
        </div>
        <div
          onClick={handleFilterBarToggle}
          title="show/hide filters"
          className="filterbar-toggle border-start border-info d-flex align-items-center px-3 py-2">
          <i className={collapsed ? 'eha-down' : 'eha-up'}></i>
        </div>
      </div>
      <div className="filters-expand border-info border-top">{children}</div>
    </div>
  );
};

export default FilterBar;