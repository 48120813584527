/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Alert, Badge, Button, Modal } from 'react-bootstrap';
import zipcelx, { ZipCelXCell, ZipCelXRow, ZipCelXDataSet, ZipCelXConfig } from 'zipcelx';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import LoadingSpinner from '@components/loading/LoadingSpinner'
import BookingDetails from '@components/booking-list/BookingDetails';

import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  getSortedRowModel,
  SortingState,
  getPaginationRowModel,
} from '@tanstack/react-table';

import { getAllBookingCount, getAllFilteredBookings } from './../../services/bookings';
import Table from '@components/table/Table';
import ActionMenu from '@components/action-menu/ActionMenu';
import { reformatDate, reformatDateFilter } from '@utils/reformatDate';
import { DebouncedInput, ServicesFilter, ProvidersFilter, AppointmentDateFilter, BranchesFilter, CreatedDateFilter, StatusFilter } from '@components/filters/BookingFilters';
import { AppState } from 'src/store';
import { BOOKINGS_TABLE_PAGE_SIZE } from './../../constants/constant'
import * as texts from '../../resources/texts'
import { statusSwitch, valueSwitch } from '@utils/tableUtil';
import { Booking } from 'src/interfaces';

const Bookings = () => {
  const { filter } = useSelector((state: AppState) => state.bookingsFilter)

  const [isExcelError, setIsExcelError] = React.useState<boolean>(false);

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState<string>('')
  const [branchFilter, setBranchFilter] = useState<string | number>('')
  const [serviceFilter, setServiceFilter] = useState<string | number>('')
  const [statusFilter, setStatusFilter] = useState<string>('')
  const [providerFilter, setProviderFilter] = useState<string | number>('')

  const [appointmentDateFilter, setAppointmentDateFilter] = useState<{ from: string, to: string }>({ from: '', to: '' });
  const [createdDateFilter, setCreatedDateFilter] = useState<{ from: string, to: string }>({ from: '', to: '' });
  const [showForm, setShowForm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: BOOKINGS_TABLE_PAGE_SIZE
  })

  // async function to fetch all booking with pagination, offset, and filters
  async function fetchData(options: {
    pageIndex: number,
    pageSize: number
  }) {
    try {
      const offset = (options.pageIndex === 0) ? 0 : (options.pageIndex * options.pageSize)
      const limit = options.pageSize

      const processedBookingDate = reformatDateFilter(appointmentDateFilter)

      const processedCreatedDate = reformatDateFilter(createdDateFilter)

      const { count } = await getAllBookingCount()
      const result = await getAllFilteredBookings({ branchId: branchFilter, services: serviceFilter, providers: providerFilter, dateFrom: processedBookingDate.from, dateTo: processedBookingDate.to, createdFrom: processedCreatedDate.from, createdTo: processedCreatedDate.to, status: statusFilter, offset, limit })

      if (result.length >= 0) {
        return {
          result,
          pageCount: Math.ceil(count / pageSize),
          count
        }
      }
    } catch (error) {
      console.log('Booking List Error', error)
    }
  }

  // react-table columns
  const columns = React.useMemo(() => [
    {
      header: 'Booking Code',
      accessorFn: (row: any) => row?.booking_code,
      id: 'booking_code',
      cell: ({ row }: any) => (
      <div style={{ cursor: 'pointer' }}>
        <span
            className="text-primary cursor-pointer"
            onClick={() => handleBookingClick(row.original)}
          >
            {row.original.booking_code}
          </span>
      </div>
        ),
    },
    {
      header: 'Created',
      accessorFn: (row: any) => row?.created_at,
      id: 'created',
      headerClassName: 'created',
      cell: (e: any) => {
        const formattedDate = reformatDate(e.getValue())
        return (
          <>
            <div>{(formattedDate && formattedDate.date) ?? formattedDate}</div>
            <div className="small"><i className="eha-clock"></i>{(formattedDate && formattedDate.time) ?? formattedDate}</div>
          </>
        )
      }
    },
    {
      header: 'Date',
      accessorFn: (row: any) => row?.appointment_date,
      id: 'Date',
      headerClassName: 'Date',
      cell: (e: any) => {
        const formattedDate = reformatDate(e.getValue().start_time)
        return (
          <>
            <div>{(formattedDate && formattedDate.date) ?? formattedDate}</div>
            <div className="small"><i className="eha-clock"></i>{(formattedDate && formattedDate.time)}</div>
          </>
        )
      }
    },
    {
      header: 'Client',
      accessorFn: (row: any) => row?.client,
      id: 'Client',
      headerClassName: 'Client',
      cell: (e: any) => {
        return (
          <>
            <div>{e.getValue().name}</div>
            <div className="small">{e.getValue().email}</div>
          </>
        )
      }
    },
    {
      header: 'Branch',
      accessorFn: (row: any) => row?.branch,
      id: 'branch',
      headerClassName: 'branch',
    },
    {
      header: 'Service',
      accessorFn: (row: any) => row?.service,
      id: 'service',
      headerClassName: 'service',
    },
    {
      header: 'Service Provider',
      accessorFn: (row: any) => row?.service_provider,
      id: 'Service Provider',
      headerClassName: 'Service Provider',
    },
    {
      header: 'Status',
      accessorFn: (row: any) => row?.status,
      id: 'Status',
      headerClassName: 'status',
      cell: (e) => <Badge bg={statusSwitch(e.getValue())}>{valueSwitch(e.getValue())}</Badge>
    }
  ], [])

  // function option parameters
  const fetchDataOptions = {
    pageIndex, pageSize
  }

  // React query call to fetch booking list
  const dataQuery = useQuery([
    'allBookings',
    pageIndex,
    branchFilter,
    serviceFilter,
    providerFilter,
    appointmentDateFilter
  ], () => fetchData(fetchDataOptions), { keepPreviousData: true, refetchOnWindowFocus: false })

  // processed booking list array
  const processedBookings = dataQuery.data && dataQuery.data.result && dataQuery.data.result.map((data: any) => {
    return {
      booking_code: data.code,
      created_at: data.create_date,
      appointment_date: { start_time: data.start_time, end_time: data.end_time },
      client: { name: data.client.name, email: data.client.email },
      branch: data.branch.name,
      service: data.service.name,
      service_provider: data.provider.name,
      status: data.status,
      origin: data.origin,
      create_username: data.create_username,
      isEmergency: data.is_emergency,
      isActive: data.is_active,
      comment: data.comment,
    }
  })


  const defaultData = React.useMemo(() => [], [])

  const pagination = React.useMemo(() => (
    {
      pageIndex, pageSize
    }
  ), [pageIndex, pageSize])

  // filter search function
  const fuzzyFilter: any = (row: { getValue: (arg0: any) => any; }, columnId: any, value: string, addMeta: (arg0: { itemRank: RankingInfo; }) => void): any => {
    // Rank the Item
    const itemRank = rankItem(row.getValue(columnId), value);
    // store the item Rank info
    addMeta({ itemRank })

    // Return if the Item should be filtered or not 
    return itemRank.passed
  }

  // Memoised booking list array
  const memoizedBookings: any = React.useMemo(
    () => (processedBookings && [...processedBookings]) ?? [],
    [processedBookings]
  );

  // React-table instance
  const table = useReactTable({
    data: memoizedBookings ?? defaultData,
    columns,
    pageCount: dataQuery?.data?.pageCount ?? -1,
    state: { pagination, sorting, globalFilter },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onGlobalFilterChange: setGlobalFilter,
    manualPagination: true,
    debugTable: true
  })

  // Async Function to download filtered booking list from the server as an excel file
  async function getFilteredBookingsExcel() {
    setIsExcelError(false)
    try {
      if (branchFilter || serviceFilter || providerFilter || statusFilter || (Object.values(appointmentDateFilter).every(value => value)) || (Object.values(createdDateFilter).every(value => value))) {
        const processedBookingDate = reformatDateFilter(appointmentDateFilter)
        const processedCreatedDate = reformatDateFilter(createdDateFilter)

        const data = await getAllFilteredBookings({ branchId: branchFilter, services: serviceFilter, status: statusFilter, providers: providerFilter, dateFrom: processedBookingDate.from, dateTo: processedBookingDate.to, createdFrom: processedCreatedDate.from, createdTo: processedCreatedDate.to })
        const config: ZipCelXConfig = {
          filename: 'Filtered-Booking-Lists',
          sheet: {
            data: data.length > 0 ? bookingsExcelData(data) : [] as ZipCelXDataSet | ZipCelXCell[][]
          }
        };
        return zipcelx(config)
      } else {
        <Alert variant='danger'>{texts.SELECT_FILTER}</Alert>
      }

    } catch (e) {
      setIsExcelError(true)
    }
  }

  // Async Function to download all booking lists from the server as an excel file
  async function getAllBookingsExcel() {
    setIsExcelError(false)
    try {
      const data = await getAllFilteredBookings({})
      const config: ZipCelXConfig = {
        filename: 'Booking-Lists',
        sheet: {
          data: data.length > 0 ? bookingsExcelData(data) : [] as ZipCelXDataSet | ZipCelXCell[][]
        }
      };
      return zipcelx(config)
    } catch (e) {
      setIsExcelError(true)
    }
  }

  // function to process excel data for excel export
  function bookingsExcelData(data: []): ZipCelXCell[][] {
    const processedData = data.length > 0 && data?.map((eachData: {
      code: string;
      create_date: string;
      start_time: string;
      end_time: string;
      client: { name: string, email: string };
      branch: { name: string };
      service: { name: string };
      provider: { name: string };
      status: string;
    }): ZipCelXRow => {
      return [
        {
          type: 'string',
          value: eachData && eachData.code
        }, {
          type: 'string',
          value: eachData && reformatDate(eachData.create_date).date
        }, {
          type: 'string',
          value: eachData && reformatDate(eachData.start_time).date
        }, {
          type: 'string',
          value: eachData && reformatDate(eachData.start_time).time + `${eachData.end_time ? '-' : ''}` + ((eachData.end_time && reformatDate(eachData.end_time).time) || '')
        }, {
          type: 'string',
          value: eachData && eachData.client && eachData.client.name
        }, {
          type: 'string',
          value: eachData && eachData.client && eachData.client.email
        }, {
          type: 'string',
          value: eachData && eachData.branch && eachData.branch.name
        }, {
          type: 'string',
          value: eachData && eachData.service && eachData.service.name
        }, {
          type: 'string',
          value: eachData && eachData.provider && eachData.provider.name
        }
      ];
    })

    // Excel headers
    const headerSheet = [{
      value: 'Booking Code',
      type: 'string'
    }, {
      value: 'Created Date',
      type: 'string'
    }, {
      value: 'Start Date',
      type: 'string'
    }, {
      value: 'Start/End Time',
      type: 'string'
    }, {
      value: 'Client Name',
      type: 'string'
    }, {
      value: 'Client Email',
      type: 'string'
    }, {
      value: 'Branch',
      type: 'string'
    }, {
      value: 'Service',
      type: 'string'
    }, {
      value: 'Provider',
      type: 'string'
    }] as ZipCelXCell[]

    const secondProcessedData = processedData && processedData.map((eachData: ZipCelXRow) => {
      return eachData
    })
    return [headerSheet].concat(secondProcessedData as ZipCelXCell[][])
  }

  const ApplyFilter = () => {
    setBranchFilter(filter.branch)
    setServiceFilter(filter.service)
    setProviderFilter(filter.serviceProvider)
    setStatusFilter(filter.status)
    setCreatedDateFilter(filter.createdAt)
    setAppointmentDateFilter(filter.appointmentDate)
    setPagination({
      pageIndex: 0,
      pageSize: 100
    })
  }

  // dropdown action list to download excel files (filtered and un-filtered)
  const actions = [
    {
      text: 'Export Filtered Records',
      handler: getFilteredBookingsExcel,
      key: 1
    },
    {
      text: 'Export All Records',
      handler: getAllBookingsExcel,
      key: 2
    }
  ]

  const handleBookingClick = (booking: Booking) => {
    setSelectedBooking(booking);
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
    setSelectedBooking(null);
  };

  function closeModal(): void {
    setShowForm(false);
    setSelectedBooking(null);
  }
  
  return (
    <>
      <Modal 
          show={showForm} 
          onHide={closeForm} 
          size="lg"
          backdrop="static"
          enforceFocus={false}
          backdropClassName='modal-min-90'
          centered>
          <div className='booking-header'/>
              <Modal.Header className='fs-4' closeButton>
                Booking Details
              </Modal.Header>
            <Modal.Body>
              {selectedBooking && (
                <BookingDetails booking={selectedBooking} />
              )}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={closeModal}>
              Close
            </Button>
            </Modal.Footer>
          
      </Modal>
      <div className='table-list table-list-admin'>
        <div>
          <div className="table-list-header d-flex p-4 pb-1">
            <div className="table-list-filters flex-wrap d-lg-flex">
              <div className="table-list-search d-flex p-3">
                <i className="eha-search fs-4 me-3"></i>
                <DebouncedInput
                  value={globalFilter ?? ''}
                  onChange={value => setGlobalFilter(String(value))} placeholder="Search all Columns..."
                />
              </div>
              <div className="d-flex">
                <AppointmentDateFilter />
                <CreatedDateFilter />
              </div>

              <div className="d-flex px-3 w-100 align-items-lg-center justify-content-end border-top border-info">
                <div className="flex-grow-1 p-2 ps-md-0 pe-md-3">
                  <BranchesFilter />
                </div>
                <div className="flex-grow-1 p-2 ps-md-0 pe-md-3">
                  <StatusFilter />
                </div>
                <div className="flex-grow-1 p-2 ps-md-0 pe-md-3">
                  <ServicesFilter />
                </div>
                <div className="flex-grow-1 p-2 ps-md-0 pe-md-3">
                  <ProvidersFilter />
                </div>
                <div className="align-items-center h-100 d-flex p-2">
                  <Button size="sm" onClick={ApplyFilter} className="w-100">Apply</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <>
          {isExcelError && (<Alert variant="danger">{texts.BOOKING_LIST_ERROR}</Alert>)}
          {(dataQuery.isLoading || dataQuery.isFetching) ? (
            <div className="body bg d-flex align-items-center justify-content-center h-100 mt-5">
              <LoadingSpinner />
            </div>
          ) : (
            <Table table={table}>

              <div className="me-3">
                <ActionMenu
                  actions={actions}
                  testID='qa-export-records-dots'
                  roleEllipseButton="ellipse-button"
                  roleActionHandler="export-excel-div"
                />
              </div>
              <div>{(dataQuery && dataQuery.data && dataQuery.data.count) ? `${dataQuery.data.count} / ${dataQuery.data.count}` : ''}</div>
            </Table>
          )}
        </>
      </div>
    </>
  )
}

export default Bookings