/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import FilterBar from '@components/filters/FilterBar';
import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  FilterFn,
} from '@tanstack/react-table';
import Table2 from '@components/table/Table2';
import { reformatDate } from '@utils/reformatDate';
import InputContainer from '@components/form/InputContainer';
import { CLIENTS_TABLE_PAGE_SIZE } from './../../constants/constant';
import { fetchClients } from '../../services/clients';
import { Button, Alert } from 'react-bootstrap';
import Input from '@components/form/Input';
import { DebouncedInput } from '@components/filters/BookingFilters';
import FilterBadge from '@components/filters/FilterBadge';

interface Client {
  name: string;
  email: string;
  phone: string;
  patient_ref: string;
  create_username: string;
  dateCreated?: string;
}

const Clients: React.FC = () => {
  const [filter, setFilter] = useState({
    name: '',
    email: '',
    phone: '',
    patientRef: '',
  }); 

  const [appliedFilter, setAppliedFilter] = useState(filter);
  const [globalFilter, setGlobalFilter] = React.useState<string>('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [fetchErrorMsg, setFetchErrorMsg] = useState('');
  const [FilterBadges, setFilterBadges] = useState(filter);
  const [isFetchError, setIsFetchError] = useState(false);
  const [clientsData, setClientsData] = useState<any>([]);
  const [searchPatient, setSearchPatient] = useState({
    state: true,
    errorMessage: '',
  });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isNameFound, setIsNameFound] = useState(false);


  const [totalClients, setTotalClients] = useState<number | null>(null);
  const [allClients, setAllClients] = useState<Client[]>([]);
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: CLIENTS_TABLE_PAGE_SIZE,
  });

  // Define fuzzyFilter function
  const fuzzyFilter: FilterFn<Client> = (row, columnId, filterValue) => {
    const rowValue = row.getValue(columnId);
    return rowValue
      ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
      : true;
  };
  
  interface Filter {
    name: string;
    email: string;
    phone: string;
    patientRef: string;
  }

  interface FilterChangeEvent {
    target: {
      name: string;
      value: string;
    };
  }

  const handleFilterChange = (e: FilterChangeEvent) => {
    const newFilter: Filter = { ...filter, [e.target.name]: e.target.value };
    setFilter(newFilter);
  };

  const applyFilter = async () => {
    setAppliedFilter(filter);
    setSearchPatient((prev) => ({ ...prev, state: false, errorMessage: '' }));
    setFilterLoading(true);
    setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
    setFilterBadges(filter);
    setFilterLoading(false);
  }; 

  const fetchData = async () => {
    try {
      let response;
      if (Object.values(appliedFilter).some((value) => value !== '')) {
        const allClients = [];
        let pageIndex = 0;
        const pageSize = 100;

        do {
          response = await fetchClients({
            pageIndex: pageIndex + 1,
            pageSize,
            filters: {
              name: appliedFilter.name,
              email: appliedFilter.email,
              phone: appliedFilter.phone,
              patient_ref: appliedFilter.patientRef,
            },
          });
          allClients.push(...response.result);
          pageIndex++;
        } while (response.result.length === pageSize);
        setAllClients(allClients);
        setTotalClients(allClients.length);
      } else {
        response = await fetchClients({
          pageIndex: pagination.pageIndex + 1,
          pageSize: pagination.pageSize,
          filters: {
            name: appliedFilter.name,
            email: appliedFilter.email,
            phone: appliedFilter.phone,
            patient_ref: appliedFilter.patientRef,
          },
        });
        setClientsData(response.result);
        setTotalClients(response.totalCount);
      }
    } catch (error) {
      if (error instanceof Error) {
        setFetchErrorMsg(error.message);
        setIsFetchError(true);
      }
    }
  };

  const fetchClientsByName = async () => {
    try {
      const response = await fetchClients({
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        filters: {
          name: searchQuery,
        },
      });
      if (response.result.length === 0) {
        setIsNameFound(true);
      } else {
        setIsNameFound(false);
      }
      setClientsData(response.result);
      setTotalClients(response.totalCount);
    } catch (error) {
      if (error instanceof Error) {
        setFetchErrorMsg(error.message);
        setIsFetchError(true);
      }
    }
  };
  
  useEffect(() => {
    fetchClientsByName();
  }, [searchQuery]);

  const tableData = Object.values(appliedFilter).some((value) => value !== '')
  ? allClients
  : clientsData; 

  const columns = React.useMemo(
    () => [
      { header: 'Name', accessorKey: 'name' },
      { header: 'Email', accessorKey: 'email' },
      { header: 'Phone Number', accessorKey: 'phone' },
      { header: 'Patient ID', accessorKey: 'patient_ref' },
      { header: 'Created By', accessorKey: 'create_username' },
      {
        header: 'Date Created',
        accessorKey: 'dateCreated',
        cell: ({ getValue }: { getValue: () => any }) => {
          const formattedDate = reformatDate(getValue() as string);
          return (
            <div>
              <div>{formattedDate?.date ?? '-'}</div>
              <small>{formattedDate?.time ?? ''}</small>
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: tableData,
    columns,
    pageCount: Math.ceil((totalClients || 1) / pagination.pageSize),
    state: { pagination, sorting, globalFilter },
    filterFns: { fuzzy: fuzzyFilter },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    manualPagination: true,
    debugTable: true,
  });

  useEffect(() => {
    fetchData();
  }, [appliedFilter, pagination.pageIndex, pagination.pageSize]);

  return (
    <>
      <div className='table-list table-list-admin client-table-appointment'>
        <div>
          <div className='table-list-header d-sm-flex p-3 p-sm-4'>
            <FilterBar
              search={
                <>
                  <i className='eha-search fs-4 me-3'></i>
                  <DebouncedInput
                    value={filter.name}
                    onChange={(value) => {
                      setFilter({ ...filter, name: String(value) });
                      setSearchQuery(String(value));
                      setPagination({
                        pageIndex: 0,
                        pageSize: CLIENTS_TABLE_PAGE_SIZE,
                      });
                    }}
                    placeholder='Search Client Name...'
                  />
                </>
              }
              appliedFilters={
                <>
                  {FilterBadges.name && (
                    <FilterBadge
                      label='Name'
                      value={FilterBadges.name}
                    />
                  )}
                  {FilterBadges.patientRef && (
                    <FilterBadge label='Patient ID' value={FilterBadges.patientRef} />
                  )}
                  {FilterBadges.email && <FilterBadge label='Email' value={FilterBadges.email} />}
                  {FilterBadges.phone && <FilterBadge label='Phone Number' value={FilterBadges.phone} />}
                </>
              }
            >
              <div className='row m-0 px-3 pt-3'>
                <div className='col-sm-4 px-2'>
                  <InputContainer label='Patient ID' name='patientRef'>
                    <Input
                      type='text'
                      name='patientRef'
                      value={filter.patientRef}
                      onChange={handleFilterChange}
                    />
                  </InputContainer>
                </div>
                <div className='col-sm-4 px-2'>
                  <InputContainer label='Phone Number' name='Phone'>
                    <Input
                      type='text'
                      name='phone'
                      value={filter.phone}
                      autoComplete='off'
                      onChange={handleFilterChange}
                    />
                  </InputContainer>
                </div>
                
                <div className='col-sm-4 px-2'>
                  <InputContainer label='Email' name='email'>
                    <Input
                      type='text'
                      name='email'
                      value={filter.email}
                      onChange={handleFilterChange}
                    />
                  </InputContainer>
                </div>
              </div>

              <div className='w-100 d-flex justify-content-end p-3'>
                <Button size='sm' className='mx-2' onClick={applyFilter}>
                  Apply Filter
                </Button>
              </div>
            </FilterBar>
          </div>
        </div>
        {isFetchError && (
          <Alert variant='danger' className='m-3'>
            {fetchErrorMsg || 'Error loading clients. Please try again.'}
          </Alert>
        )}

        <div className='table-container'>
          {filterLoading ? (
            <div className="body bg d-flex align-items-center justify-content-center h-100 mt-5">
            <LoadingSpinner />
            </div>
          ) : isNameFound ? (
            <div className='no-result-found'>
              <div className="body bg d-flex align-items-center justify-content-center h-100 mt-5">
              <img src='/img/Search.svg' />
              </div>
              <h5 className='text-center'>No Result Found</h5>
              <p className='text-center'>
                We couldn’t find what you searched for,
                <br />
                please check your spelling or try searching for another
                <br />
                 item.
              </p>
            </div>
          ) : (
            <Table2 table={table}>
              <span>
                Showing {tableData.length || 0} / {totalClients ?? 0} Clients
              </span>
            </Table2>
          )}
        </div>
      </div>
    </>
  );
};
export default Clients;