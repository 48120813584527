import ApiException from '@components/exceptions/ApiExceptions';
import axios, { AxiosError } from 'axios';

interface Client {
    id: number;
    name: string;
    email: string;
    phone: string;
    dob: string;
    patient_ref: string;
    create_username: string;
    create_date: string;
    write_username: string;
    write_date: string;
}
interface FilteredClientOptions {
    name?: string;
    email?: string;
    phone?: string;
    patient_ref?: string;
    create_username?: string;
    create_date?: string;
}
export interface FetchClientsParams {
    pageIndex: number;
    pageSize: number;
    filters?: FilteredClientOptions;
    
}
export interface FetchClientsResponse {
    result: Client[];
    pageCount: number;
    totalCount: number;
}

export const fetchClients = async ({
    pageIndex,
    pageSize,
    filters,
  }: FetchClientsParams): Promise<FetchClientsResponse> => {
    try {
      const queryParams = new URLSearchParams({
        page: pageIndex.toString(),
        // page: (pageIndex + 1).toString(), // Adjust page index to match API expectation
        size: pageSize.toString(),
      });
  
      if (filters) {
        for (const [key, value] of Object.entries(filters)) {
          if (value) {
            switch (key) {
              case 'name':
              case 'email':
              case 'phone':
              case 'patient_ref':
                queryParams.append(key, value);
                break;
              default:
                // Ignore other filter keys
                break;
            }
          }
        }
      }
  
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/clients?${queryParams.toString()}`
      );
  
      const data = response.data;
  
      if (!data || !data.data || !Array.isArray(data.data)) {
        throw new ApiException(500, 'Invalid response structure from the server');
      }
  
      return {
        result: data.data,
        pageCount: data.total_pages,
        totalCount: data.total_count,
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const statusCode = error.response?.status || 500;
        const errorMessage = (error.response?.data as { message?: string })?.message || error.message;
        throw new ApiException(statusCode, errorMessage);
      }
  
      throw new Error(`Error fetching clients: ${(error as Error).message}`);
    }
  };