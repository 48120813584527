import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import FilterBar from '@components/filters/FilterBar';
import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  FilterFn,
} from '@tanstack/react-table';
import Table2 from '@components/table/Table2';
import { reformatDate } from '@utils/reformatDate';
import InputContainer from '@components/form/InputContainer';
import { CLIENTS_TABLE_PAGE_SIZE } from './../../constants/constant';
import { fetchClients, getClientCount } from '../../services/clients';
import { Button, Alert } from 'react-bootstrap';
import Input from '@components/form/Input';
import { DebouncedInput } from '@components/filters/BookingFilters';
import FilterBadge from '@components/filters/FilterBadge';

interface Client {
  name: string;
  email: string;
  phone: string;
  patient_ref: string;
  createUsername: string;
  dateCreated: string;
}

const Clients: React.FC = () => {
  const [filters] = useState<Record<string, string>>({
    name: '',
    email: '',
    phone: '',
    patientRef: '',
    createUsername: '',
    dateCreated: '',
  });

  const [filter, setFilter] = useState({
    email: '',
    name: '',
    patientRef: '',
  });

  const fetchPatients = false;  
  const [globalFilter, setGlobalFilter] = React.useState<string>('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [fetchErrorMsg, setFetchErrorMsg] = useState('');
  const [FilterBadges, setFilterBadges] = useState(filter);
  const [isFetchError, setIsFetchError] = useState(false);
  const [clientsData, setClientsData] = useState<any>([]);
  const [searchPatient, setSearchPatient] = useState({
    state: !fetchPatients ? true : false,
    errorMessage: '',
  });

  const [totalClients, setTotalClients] = useState(0);

  useQuery(['clientCount'], getClientCount, {
    onSuccess: (data) => {
      setTotalClients(data.count || 0);
    },
  });

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: CLIENTS_TABLE_PAGE_SIZE,
  });
  
  const {
    isLoading,
    isError,
  } = useQuery(
    ['clients', pagination.pageIndex, pagination.pageSize, filters],
    () =>
      fetchClients({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
        filters,
        }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setClientsData(data);
      },
      onError: (error) => {
        if (error instanceof Error) {
          setFetchErrorMsg(error.message);
          setIsFetchError(true);
        }
      },
    },
  );

  // Define fuzzyFilter function
  const fuzzyFilter: FilterFn<Client> = (row, columnId, filterValue) => {
    const rowValue = row.getValue(columnId);
    return rowValue
      ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
      : true;
  };
  
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const applyFilter = async () => {
    setSearchPatient((prev) => ({ ...prev, state: false, errorMessage: '' }));
    setFilterLoading(true);
  
    const filtersList = [
      // eslint-disable-next-line camelcase
      filter.patientRef && { patient_ref: filter.patientRef },
      filter.name && { name: filter.name },
      filter.email && { email: filter.email },
    ].filter(Boolean);
  
    const isFilterEmpty = filtersList.length === 0;
  
    const requestFilters = isFilterEmpty ? {} : {
      name: filter.name,
      email: filter.email,
      // eslint-disable-next-line camelcase
      patient_ref: filter.patientRef,
    };
  
    try {
      const response = await fetchClients({
        pageIndex: 0,
        pageSize: CLIENTS_TABLE_PAGE_SIZE,
        filters: requestFilters,
      });
  
      if (isFilterEmpty) {
        setPagination({ pageIndex: 0, pageSize: CLIENTS_TABLE_PAGE_SIZE });
      } else {
        setFilterBadges(filter);
      }
  
      setClientsData(response);
  
      if (!response?.result || response.result.length === 0) {
        setSearchPatient({
          ...searchPatient,
          state: false,
          errorMessage: 'No matching patients found. Please adjust your filters and try again.',
        });
      }else {
        setFilterBadges(filter);
      }
    } catch (error) {
      setSearchPatient({
        ...searchPatient,
        state: false,
        errorMessage: `An error occurred: ${(error as Error).message}`,
      });
    }
    setFilterLoading(false);
  };  

  const tableData = clientsData?.result || [];

  const columns = React.useMemo(
    () => [
      { header: 'Name', accessorKey: 'name' },
      { header: 'Email', accessorKey: 'email' },
      { header: 'Phone Number', accessorKey: 'phone' },
      { header: 'Patient ID', accessorKey: 'patient_ref' },
      { header: 'Created By', accessorKey: 'create_username' },
      {
        header: 'Date Created',
        accessorKey: 'dateCreated',
        cell: ({ getValue }: { getValue: () => any }) => {
          const formattedDate = reformatDate(getValue() as string);
          return (
            <div>
              <div>{formattedDate?.date ?? '-'}</div>
              <small>{formattedDate?.time ?? ''}</small>
            </div>
          );
        },
      },
    ],
    [],
  );

  const table = useReactTable({
    data: tableData,
    columns,
    pageCount: Math.ceil(totalClients / pagination.pageSize),
    state: { pagination, sorting, globalFilter },
    filterFns: { fuzzy: fuzzyFilter },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    manualPagination: true,
    debugTable: true,
  });

  return (
    <>
      <div className='table-list table-list-admin client-table-appointment'>
        <div>
          <div className='table-list-header d-sm-flex p-3 p-sm-4'>
            <FilterBar
              search={
                <>
                  <i className='eha-search fs-4 me-3'></i>
                  <DebouncedInput
                    value={globalFilter ?? ''}
                    onChange={(value) => {
                      setPagination({
                        pageIndex: 0,
                        pageSize: CLIENTS_TABLE_PAGE_SIZE,
                      });
                      setGlobalFilter(String(value));
                    }}
                    placeholder='Search all Columns...'
                  />
                </>
              }
              appliedFilters={
                <>
                  {FilterBadges.name && (
                    <FilterBadge
                      label='Name'
                      value={FilterBadges.name}
                    />
                  )}
                  {FilterBadges.patientRef && (
                    <FilterBadge label='Patient ID' value={FilterBadges.patientRef} />
                  )}
                  {FilterBadges.email && <FilterBadge label='Email' value={FilterBadges.email} />}
                </>
              }
            >
              <div className='row m-0 px-3 pt-3'>
                <div className='col-sm-4 px-2'>
                  <InputContainer label='Patient ID' name='patientRef'>
                    <Input
                      type='text'
                      name='patientRef'
                      value={filter.patientRef}
                      onChange={handleFilterChange}
                    />
                  </InputContainer>
                </div>
                <div className='col-sm-4 px-2'>
                  <InputContainer label='Name' name='Name'>
                    <Input
                      type='text'
                      name='name'
                      value={filter.name}
                      autoComplete='off'
                      onChange={handleFilterChange}
                    />
                  </InputContainer>
                </div>
                
                <div className='col-sm-4 px-2'>
                  <InputContainer label='Email' name='email'>
                    <Input
                      type='text'
                      name='email'
                      value={filter.email}
                      onChange={handleFilterChange}
                    />
                  </InputContainer>
                </div>
              </div>

              <div className='w-100 d-flex justify-content-end p-3'>
                <Button size='sm' className='mx-2' onClick={applyFilter}>
                  Apply Filter
                </Button>
              </div>
            </FilterBar>
          </div>
        </div>
        {isError && (
          <Alert variant='danger' className='m-3'>
            {fetchErrorMsg || 'Error loading clients. Please try again.'}
          </Alert>
        )}

        <div className='table-container'>
          {isLoading ? (
            <div className="body bg d-flex align-items-center justify-content-center h-100 mt-5">
            <LoadingSpinner />
            </div>
          ) : (
            <Table2 table={table}>
              <span>
              Showing {clientsData?.result?.length ?? 0} / {totalClients} Clients
              </span>
            </Table2>
          )}
        </div>
      </div>
    </>
  );
};
export default Clients;
