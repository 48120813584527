import ApiException from '@components/exceptions/ApiExceptions';
import axios from 'axios';
import * as servicesSlice from '../store/ServicesSlice';
interface Error {
  response: {
    status: number;
  };
}
interface ServiceBranches {
  branch_id: number;
  service_id: number;
}

export const getAllServices = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/services`);
    return result.data;
  } catch (error) {
    const typedError = error as Error;
    throw new ApiException(typedError.response.status);
  }
};
export const getService = async (id: number) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/services/${id}`);
    return result.data;
  } catch (error) {
    const typedError = error as Error;
    throw new ApiException(typedError.response.status);
  }
};
export const createService = async (params: servicesSlice.Service) => {
  try {
    const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/services`, params);
    return result.data;
  } catch (error) {
    const typedError = error as Error;
    throw new ApiException(typedError.response.status);
  }
};
export const updateService = async (params: servicesSlice.Service) => {
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/services/${params.id}`,
      params,
    );
    return result.data;
  } catch (error) {
    const typedError = error as Error;
    throw new ApiException(typedError.response.status);
  }
};
export const updateServiceBranches = async (params: ServiceBranches) => {
  try {
    const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/serviceBranches`, params);
    return result.data;
  } catch (error) {
    const typedError = error as Error;
    throw new ApiException(typedError.response.status);
  }
};
export const deleteService = async (params: servicesSlice.Service) => {
  try {
    const result = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/services/${params.id}`);
    return result;
  } catch (error) {
    const typedError = error as Error;
    throw new ApiException(typedError.response.status);
  }
};
export const deleteServiceBranch = async (params: ServiceBranches) => {
  try {
    const result = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/services/${params.service_id}/branches/${params.branch_id}`,
    );
    return result;
  } catch (error) {
    const typedError = error as Error;
    throw new ApiException(typedError.response.status);
  }
};
