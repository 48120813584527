import ApiException from '@components/exceptions/ApiExceptions';
import axios, { AxiosError } from 'axios';

interface Client {
    id: number;
    name: string;
    email: string;
    phone: string;
    dob: string;
    patient_ref: string;
    create_username: string;
    create_date: string;
    write_username: string;
    write_date: string;
}
interface FilteredClientOptions {
    name?: string;
    email?: string;
    phone?: string;
    patient_ref?: string;
    create_username?: string;
    create_date?: string;
}
export interface FetchClientsParams {
    pageIndex: number;
    pageSize: number;
    filters?: FilteredClientOptions;
    email?: string;
    patientRef?: string;
    name?: string;
}
export interface FetchClientsResponse {
    result: Client[];
    pageCount: number;
    totalCount: number;
}

export const getClientCount = async () => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/count_clients`);
      return result.data;
    } catch (error) {
      const err = error as AxiosError;
      throw new ApiException(err.response?.status || 500);
    }
  };

export const fetchClients = async ({
    pageIndex,
    pageSize,
    filters,
}: FetchClientsParams): Promise<FetchClientsResponse> => {
    try {
        const queryParams = new URLSearchParams({
            offset: (pageIndex * pageSize).toString(),
            limit: pageSize.toString(),
        });
        // Append filter options to query parameters
        for (const [key, value] of Object.entries(filters || {})) {
            if (value) queryParams.append(key, value);
        }
        const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/clients?${queryParams.toString()}`
        );
        const data = response.data;
        if (!data || !Array.isArray(data) ) {
            throw new ApiException(500, 'Invalid response structure from the server');
        }

        return {
            result: data,
            pageCount: Math.ceil(data.length / pageSize),
            totalCount: data.length,
        };
    } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
            const statusCode = error.response?.status || 500;
            const errorMessage = (error.response?.data as { message?: string })?.message || error.message;
            throw new ApiException(statusCode, errorMessage);
        }

        throw new Error(`Error fetching clients: ${(error as Error).message}`);
    }
};
