import { createSlice } from '@reduxjs/toolkit';
import { TimePickerValue } from 'react-time-picker';

export interface SelectDropdown {
  value: string;
  label: string;
}

export type Schedule = {
  day: string;
  active: boolean;
  sunrise: {
    startTime: TimePickerValue;
    endTime: TimePickerValue;
  };
  sunset: {
    startTime: TimePickerValue;
    endTime: TimePickerValue;
  };
};

export interface Service {
  id?: number;
  name: string;
  desc: string;
  code: string;
  color: string;
  icon: string;
  duration: string;
  limit_booking: string;
  currency_code?: string;
  is_visible?: boolean;
  is_active?: boolean;
  create_date?: string;
  create_username: string;
  google_meet_required?: boolean;
}
export interface ServiceBranches {
  address: string;
  code: string;
  id: number;
  is_testcenter: boolean;
  name: string;
}

const services: Service[] = [];
const selectedService = <Service>{};
const serviceBranches: ServiceBranches[] = [];

const initialState = {
  searchInput: '',
  services,
  selectedService,
  serviceBranches,
};

const servicesSlice = createSlice({
  name: 'Services',
  initialState,
  reducers: {
    allServices: (state, { payload }) => {
      state.services = payload;
    },
    searchService: (state, { payload }) => {
      state.searchInput = payload;
    },
    selectService: (state, { payload }) => {
      state.selectedService = payload;
    },
    setServiceBranches: (state, { payload }) => {
      state.serviceBranches = payload;
    },
  },
});
export const { searchService, selectService, allServices, setServiceBranches } =
  servicesSlice.actions;
export default servicesSlice.reducer;
