/* eslint-disable camelcase */
import { Modal } from 'react-bootstrap';
import InputContainer from '@components/form/InputContainer';
import FormButton from '@components/form/FormButton';
import DeleteButton from '@components/form/DeleteButton';
import Select, { MultiValue } from 'react-select';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store';
import * as servicesSlice from '../../store/ServicesSlice';
import * as servicesService from '../../services/serviceServices';
import * as branchServices from '../../services/branchServices';
import { getAddedBranches, getRemovedBranches } from '@utils/branches';
import { useQuery } from '@tanstack/react-query';

type Props = {
  show: boolean;
  onHide: () => void;
  service: servicesSlice.Service;
};
interface BranchOptions {
  branch_id: number;
  label: string;
  value: string;
}

interface BooleanOptions {
  label: string;
  value: boolean;
}

const EditAndDeleteModal = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { service } = props;
  const theServiceDetails = service;
  const [updateServiceDetails, setUpdateServiceDetails] = useState(theServiceDetails);

  const [existingBranches, setExistingBranches] = useState<BranchOptions[]>([]);
  const [selectedBranches, setSelectedBranches] = useState<MultiValue<BranchOptions>>([]);
  const [allBranches, setAllBranches] = useState<BranchOptions[]>([]);

  const [addedBranches, setAddedBranches] = useState<MultiValue<BranchOptions>>([]);
  const [removedBranches, setRemovedBranches] = useState<MultiValue<BranchOptions>>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setUpdateServiceDetails(theServiceDetails);
  }, [theServiceDetails]);

  const { isSuccess } = useQuery(
    ['service', updateServiceDetails.id],
    () => servicesService.getService(updateServiceDetails.id as number),
    {
      refetchOnWindowFocus: false,
      onSuccess: (service) => {
        const serviceBranches = service.branches.map((eachBranch: { id: number; name: string }) => {
          const branchOptionsDropdown = {} as BranchOptions;
          branchOptionsDropdown.branch_id = eachBranch.id as number;
          branchOptionsDropdown.label = eachBranch.name;
          branchOptionsDropdown.value = eachBranch.name.toLowerCase();
          return branchOptionsDropdown;
        });

        setExistingBranches(serviceBranches);
        setSelectedBranches(serviceBranches);
      },
    },
  );

  useQuery(['branches'], () => branchServices.getAllBranches(), {
    refetchOnWindowFocus: false,
    onSuccess: (branches) => {
      const transformedBranches = branches.map((eachBranch: { id: number; name: string }) => {
        const allBranchesOptionsDropdown = {} as BranchOptions;
        allBranchesOptionsDropdown.branch_id = eachBranch.id as number;
        allBranchesOptionsDropdown.label = eachBranch.name;
        allBranchesOptionsDropdown.value = eachBranch.name.toLowerCase();

        return allBranchesOptionsDropdown;
      });
      setAllBranches(transformedBranches);
    },
  });

  const updateBranchOptions = (branches: MultiValue<BranchOptions>) => {
    setSelectedBranches(branches);

    const newlyAddedBranches = getAddedBranches(existingBranches, branches);
    const newlyRemovedBranches = getRemovedBranches(existingBranches, branches);
    setAddedBranches(newlyAddedBranches);
    setRemovedBranches(newlyRemovedBranches);
  };
  const updateService = async () => {
    setIsSaving(true);
    if (
      updateServiceDetails.name.length > 0 &&
      updateServiceDetails.desc.length > 0 &&
      updateServiceDetails.duration.toString().trim() !== '' &&
      updateServiceDetails.limit_booking.toString().trim() !== ''
    ) {
      const updatedService = await servicesService.updateService(updateServiceDetails);

      for (const eachBranch of removedBranches) {
        const serviceBranches = {
          branch_id: eachBranch.branch_id,
          service_id: updateServiceDetails.id as number,
        };
        await servicesService.deleteServiceBranch(serviceBranches);
      }
      for (const eachBranch of addedBranches) {
        const serviceBranches = {
          branch_id: eachBranch.branch_id,
          service_id: updateServiceDetails.id as number,
        };
        await servicesService.updateServiceBranches(serviceBranches);
      }

      dispatch(servicesSlice.selectService(updatedService));
      const serviceData = await servicesService.getService(service.id as number);
      dispatch(servicesSlice.setServiceBranches(serviceData.branches));
      setIsSaving(false);
      props.onHide();
    } else {
      setError(true);
    }
  };

  const deleteService = async () => {
    const deletedService = await servicesService.deleteService(updateServiceDetails);
    if (deletedService && deletedService.status === 204) {
      const services = await servicesService.getAllServices();
      dispatch(servicesSlice.selectService(services[0]));
      dispatch(servicesSlice.allServices(services));
      props.onHide();
    }
  };

  const handleServiceVisibiltyChange = (visibility: BooleanOptions | null) => {
    setUpdateServiceDetails((prev) => ({
      ...prev,
      is_visible: visibility?.value,
    }));
  };

  const handleServiceGoogleMeetChange = (googleMeet: BooleanOptions | null) => {
    setUpdateServiceDetails((prev) => ({
      ...prev,
      google_meet_required: googleMeet?.value,
    }));
  };

  return (
    <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Edit Service Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSuccess && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='name' label='Service Name' required={true}>
                  <input
                    type='text'
                    defaultValue={updateServiceDetails.name}
                    onChange={(item) =>
                      setUpdateServiceDetails({
                        ...updateServiceDetails,
                        name: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-3'>
                <InputContainer name='icon' label='Service Icon'>
                  <input
                    type='text'
                    defaultValue={updateServiceDetails.icon}
                    onChange={(item) =>
                      setUpdateServiceDetails({
                        ...updateServiceDetails,
                        icon: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-3'>
                <InputContainer name='code' label='Service Code' required={true}>
                  <input
                    type='text'
                    defaultValue={updateServiceDetails.code}
                    onChange={(item) =>
                      setUpdateServiceDetails({
                        ...updateServiceDetails,
                        code: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <InputContainer name='Description' label='Description' required={true}>
              <textarea
                defaultValue={updateServiceDetails.desc}
                onChange={(item) =>
                  setUpdateServiceDetails({ ...updateServiceDetails, desc: item.target.value })
                }
                rows={2}
              ></textarea>
            </InputContainer>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='duration' label='Service Duration' required={true}>
                  <div className='d-flex align-items-center'>
                    <input
                      type='number'
                      defaultValue={updateServiceDetails.duration}
                      onChange={(item) =>
                        setUpdateServiceDetails({
                          ...updateServiceDetails,
                          duration: item.target.value,
                        })
                      }
                    />
                    <span className=' '>Minutes</span>
                  </div>
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='booking' label='Limit Booking' required={true}>
                  <div className='d-flex align-items-center'>
                    <input
                      type='number'
                      defaultValue={updateServiceDetails.limit_booking}
                      onChange={(item) =>
                        setUpdateServiceDetails({
                          ...updateServiceDetails,
                          limit_booking: item.target.value,
                        })
                      }
                    />
                  </div>
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='location' label='Branches'>
                  <Select
                    instanceId='Branches'
                    value={selectedBranches}
                    isMulti
                    onChange={(branches) => updateBranchOptions(branches)}
                    options={allBranches}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='color' label='Color Code'>
                  <input
                    type='text'
                    defaultValue={updateServiceDetails.color}
                    onChange={(item) =>
                      setUpdateServiceDetails({
                        ...updateServiceDetails,
                        color: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>

              {/* Service isActive */}
              <div className='col-md-6'>
                <InputContainer name='is_visible' label='Is Visible'>
                  <Select
                    instanceId='is_visible'
                    value={{
                      label: updateServiceDetails.is_visible ? 'Yes' : 'No',
                      value: updateServiceDetails.is_visible ? true : false,
                    }}
                    onChange={handleServiceVisibiltyChange}
                    options={[
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ]}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>

              <div className='col-md-6'>
                <InputContainer name='google_meet_required' label='Google meet required'>
                  <Select
                    instanceId='google_meet_required'
                    value={{
                      label: updateServiceDetails.google_meet_required ? 'Yes' : 'No',
                      value: updateServiceDetails.google_meet_required ? true : false,
                    }}
                    onChange={handleServiceGoogleMeetChange}
                    options={[
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ]}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
            </div>
          </>
        )}
        <div className='text-danger text-center my-2'>
          {error && 'One or more items is required'}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <DeleteButton name='Service' onClick={deleteService}>
          Delete
        </DeleteButton>
        <FormButton className='ms-auto' loading={isSaving} onClick={updateService}>
          Save
        </FormButton>
      </Modal.Footer>
    </Modal>
  );
};
export default EditAndDeleteModal;
