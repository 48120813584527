import React from 'react';

interface FilterBadgeProps {
  label: string;
  value: string;
}

const FilterBadge = ({ label, value }: FilterBadgeProps) => {
  return (
    <div className="filter-badge">
      <span className="label me-2">{label}</span>
      {value}
    </div>
  );
};

export default FilterBadge;
