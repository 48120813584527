import { Badge } from 'react-bootstrap';
import { statusSwitch, valueSwitch } from '@utils/tableUtil';
import { Booking } from 'src/interfaces';
import { formatDateTime } from '@utils/reformatDate' 

const BookingDetails = ({ booking }: { booking: Booking }) => {
          
  return (
    <div className="booking-details-modal">
        <div className='d-flex align-items-center justify-content-between w-100'>
          <div>    
            <Badge bg={statusSwitch(booking.status)}>{valueSwitch(booking.status)}</Badge>
            {booking.isActive && <Badge bg="success" className="ms-2">Active</Badge>}
          </div>
          <div className='d-flex justify-content-end'>{booking.isEmergency && <Badge bg="danger">Emergency</Badge>}</div>
        </div>
        <div className='d-flex align-items-center justify-content-between w-100 mt-3'>
          <div>
            <h5 className="mb-0">{booking.client?.name}</h5>
            <p className="text-muted mb-0 mt-1">{booking.client.email}</p>
          </div>
        
          <div className='d-flex align-items-end flex-column'>
            <p className="mb-0 text-muted">{booking.service}</p>
            <p className="text-primary mb-0 mt-1">{booking.booking_code}</p>
          </div>
        </div>
          <div className='booking-divider w-100 mt-3 mb-3'          
          />       

          {/* Booking Information */}
        <div className="mb-4">
          <div className="row mb-3">
            <div className="col-6">
              <em className="mb-0 text-muted">Provider's Name</em>
              <h5>{booking.service_provider}</h5>
            </div>
            <div className="col-6">
              <em className="mb-0 text-muted">Branch Name</em>
              <h5>{booking.branch}</h5>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <em className="mb-0 text-muted">Created By</em>
              <h5>{booking.create_username}</h5>
            </div>
            <div className="col-6">
              <em className="mb-0 text-muted">Date Created</em>
              <h5>{formatDateTime(booking.created_at, 'DD MMM YYYY hh:mm A')}</h5>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <em className="mb-0 text-muted">Origin</em>
              <h5>{booking.origin}</h5>
            </div>
            <div className="col-6">
              <em className="mb-0 text-muted">Start Time</em>
              <h5>{formatDateTime(booking.appointment_date.start_time, 'DD MMM YYYY hh:mm A')} </h5>
            </div>
          </div>
        </div>

        {/* Comment Section */}
        <div>
          <em className="mb-0 text-muted">Comment</em>
          <h5>
            {booking.comment ||
              'No comments provided for this booking. Please check additional notes if available.'}
          </h5>
        </div>
    </div>
  );
};

export default BookingDetails;
